import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Contact from '../components/Contact'
import InvestorHeroSection from '../components/investor/InvestorHeroSection'
import { INVESTOR_COLOR, INVESTOR_COLOR_SECONDARY } from '../../theme'
import InvestorButton from '../components/investor/InvestorButton'
import InvestorContactBg from '../components/investor/InvestorContactBg'
import InvestorInfoSection from '../components/investor/InvestorInfoSection'
import footerLogoSvg from '../images/investor-logo-footer.svg'

const heroTitle = "trois mois d’arrêt d’activité = une blessure fatale"

const heroParagraph = <>...du fond de <b>la crise</b>,  quand la menace, ils sont morts tous et reste <b>dégueulasse!</b></>

const infoList = [
  <>Dans le cadre de la crise du Covid-19 la RBF représente une bonne base pour <b>faire face à une crise</b></>,
  <>Pas besoin de formalités compliquées pour alléger les engagements financiers!</>,
  <b>Toujours proportionnels au chiffre d’affaires réalisé!</b>,
]


const infoParagraph = <>
  <b>La revenue based finance (RBF)</b> ou “financement en échange de royalties” consiste à financer une entreprise qui verse en contrepartie un pourcentage de son chiffre d’affaires à ses financeurs
</>

const infoAddonText = <>
  grâce à <b>incubit restart = Revenue Based Finance </b>= vraie alternative <br /> <br />
s’adapte par nature: baisse d’activité = baisse automatique des versements de royalties
</>

const renderSubmit = () => <InvestorButton variant="contained" type={'subbmit'}>
  DÉPOSER UN DOSSIER
</InvestorButton>

const InvestorPage = props => {
  return (
    <Layout footerProps={{ bgcolor: INVESTOR_COLOR_SECONDARY, logo: footerLogoSvg, linkStyle: { color: INVESTOR_COLOR } }}>
      <Seo title="Incubit -Investor" />

      <InvestorHeroSection 
        title={heroTitle}
        paragraph={heroParagraph}
        buttonLabel="SUBMIT AN APPLICATION"
      />

      <InvestorInfoSection
        title="À propos du marché financier aujourd'hui"
        paragraph={infoParagraph}
        items={infoList}
        addonText={infoAddonText}
      />

      <Contact formName="investor" renderSubmit={renderSubmit} bgComponent={InvestorContactBg} />
    </Layout>
  )
}

export default InvestorPage
