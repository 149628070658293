import React from 'react'
import { Button, withStyles, darken } from '@material-ui/core'
import { INVESTOR_COLOR } from '../../../theme'

const InvestorButton = withStyles(theme => ({
    root: {
        backgroundColor: INVESTOR_COLOR,
        color: theme.palette.getContrastText('#fff'),
        

        '&:hover, &:focus': {
            backgroundColor: darken(INVESTOR_COLOR, .1),
        }
    }
}))(Button)

export default InvestorButton