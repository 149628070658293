import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Container } from '@material-ui/core'
import Text from '../Text'
import { useMobile } from '../../utils/hooks'
import { INVESTOR_COLOR, INVESTOR_COLOR_SECONDARY } from '../../../theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
    list: {
        listStyle: 'none',
        [theme.breakpoints.up('xs')]: {
            padding: 0
        }
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    dotWrapper: {
        display: 'inline-block',
        marginRight: 7,
    },
    dot: {
        verticalAlign: 'middle',
        marginTop: 2,
        fontSize: '.85em'
    }
}))


const InvestorInfoSection = ({ title, paragraph, items, addonText }) => {
    const classes = useStyles()
    const mobile = useMobile()

    const titleColor = INVESTOR_COLOR_SECONDARY
    const dotColor = INVESTOR_COLOR_SECONDARY

    return (<>
        <Box id="info" py={mobile ? 4 : 8} color={"rgba(0, 0, 0, 0.87)"} bgcolor={INVESTOR_COLOR}>
            <Container>
                <Text variant="h2" className={classes.header} mb={4} color={titleColor} textAlign="left" data-sal="fade" data-sal-duration="500" data-sal-delay="200">
                    {title}
                </Text>

                <Box mt={4} data-sal="fade" data-sal-duration="1000" data-sal-delay="400">
                    <Grid container alignItems="flex-start" className={classes.list} spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Text fontWeight={400} variant="h2" pr={3}>
                                {paragraph}
                            </Text>
                        </Grid>

                        <Grid container item xs={12} md={6} spacing={2}>
                            {items.map(item => <Grid item xs={12} className={classes.listItem}>
                                <Box color={dotColor} className={classes.dotWrapper}>                                    
                                    <FontAwesomeIcon icon="circle" fontSize="inherit" className={classes.dot} />
                                </Box>

                                <Text variant="body1" component="span">
                                    {item}
                                </Text>
                            </Grid>)}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
        <Box bgcolor={INVESTOR_COLOR_SECONDARY} py={7}>
            <Container>
                <Box textAlign="center">
                    <Text variant="body1" component="span" data-sal="fade" data-sal-duration="500" data-sal-delay="400">
                        {addonText}
                    </Text>
                </Box>
            </Container>
        </Box>
    </>
    )
}

export default InvestorInfoSection
